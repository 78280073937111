import './App.css';
import NavbarComponent from './homepage/NavbarComponent';
import WelcomeComponent from './homepage/WelcomeComponent';

function App() {
  return (
    <>
      <div className="App">
        <NavbarComponent />
      </div>
      <section className="home">
        <WelcomeComponent />
      </section>
      </>
    );
}

export default App;
